import { client } from "@utils/axios-utils";
import { setItem } from "@utils/localStorage";
import { userLogout } from "@utils/userLogout";
import { GetAccessTokenResponse } from "./getAccessToken";

export const refreshToken = (refresh_token: JWT) =>
  client
    .post<GetAccessTokenResponse>(
      "auth/refresh",
      {},
      {
        headers: {
          Authorization: `Bearer ${refresh_token}`,
        },
      },
    )
    .then((response) => {
      setItem("access_token", response.data.data.access_token);
      setItem("refresh_token", response.data.data.refresh_token);
    })
    .catch(() => {
      userLogout();
    });
