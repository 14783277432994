import axios from "axios";
import { toast } from "sonner";
import { refreshToken } from "@queries/refreshToken";
import { deleteItem, getItem, setItem } from "./localStorage";
import { userLogout } from "./userLogout";
import { BASE_CONFIG } from "../../config/baseConfig";

export const client = axios.create({
  baseURL: `${BASE_CONFIG.base_backend_url}/v1/`,
});

client.defaults.headers.common["Access-Control-Allow-Headers"] =
  "x-datadog-trace-id, x-datadog-parent-id, x-datadog-origin, x-datadog-sampling-priority";

client.interceptors.request.use(
  (config) => {
    const access_token = getItem("access_token");
    const phone_number = getItem("phone_number");

    if (access_token && !config.headers.Authorization) {
      config.headers.Authorization = `Bearer ${access_token}`;
    }

    if (phone_number) {
      config.headers["Phone-Number"] = phone_number;
    }

    config.headers["x-api-key"] = getItem("api_key");
    config.headers["x-org-code"] = getItem("org_code");

    return config;
  },
  (error) => {
    return error;
  },
);

client.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const errorMessage = error.response?.data.error.type as string;

    const errorCode = error.response?.data.error.code;
    const errorValidationMessage: string =
      error.response?.data.error.messages?.jwt ?? "";

    if (["METHOD_NOT_ALLOWED"].includes(errorCode)) {
      console.error("You are unable to make this action");
    }

    // TODO - Change interceptor for authentication errors.

    if (error.response.status === 500) {
      toast.error(
        "Uh-oh! It seems like we're experiencing some hiccups on our end. Please give the page a quick refresh and try again.",
      );
    }

    if (
      errorMessage?.includes("Not enough segments") &&
      error.response.status === 422
    ) {
      deleteItem("access_token");
      deleteItem("federated_access_token");
    }

    if (
      (["AUTHENTICATION_ERROR", "Signature verification failed"].includes(
        errorMessage,
      ) ||
        errorValidationMessage.toLowerCase() === "invalid token") &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const refresh_token = getItem("refresh_token");

      if (refresh_token) {
        setItem("access_token", refresh_token);
        deleteItem("refresh_token");

        await refreshToken(refresh_token);
      } else {
        userLogout();
      }

      return client(originalRequest);
    }

    if (originalRequest._retry) {
      userLogout();
      typeof window !== "undefined" && location.replace("/");
      return;
    }

    return Promise.reject(error);
  },
);
