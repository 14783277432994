"use client";
import { datadogRum } from "@datadog/browser-rum";
import { customPostMessage, messageListener } from "@global/messaging";
import { jam } from "@jam.dev/sdk";
import { useQueryClient } from "@tanstack/react-query";
import { getItem } from "@utils/localStorage";
import { userLogout } from "@utils/userLogout";
import * as React from "react";
import { UserData } from "@queries/getUser";

export interface Auth {
  isAuthenticated: boolean;
  setUser: (username: UserData["data"] | null) => void;
  user: UserData["data"] | null;
  closeFrame: () => void;
  show: boolean;
  loading: boolean;
  setLoading: (newState: boolean) => void;
}

export const AuthContext = React.createContext<Auth | null>(null);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const queryClient = useQueryClient();

  const [user, setUser] = React.useState<UserData["data"] | null>(null);
  const [show, setShow] = React.useState<boolean>(true);
  const [loading, setLoading] = React.useState<boolean>(false);

  const isAuthenticated = !!user;

  const handleSetUser = (data: UserData["data"] | null) => {
    if (!user && data) {
      const email = data.email ?? getItem("email");

      datadogRum.setUser({
        id: data.id,
        email: email!,
      });

      jam.metadata(() => ({
        timeSincePageLoad: performance.now(),
        userId: data.id,
        email: email!,
      }));

      customPostMessage(
        {
          type: "authenticate",
          name: email,
          id: data.id,
        } as AuthenticateMessage,
        "*",
        window.parent,
      );
    }

    setUser(data);

    if (data === null) {
      queryClient.invalidateQueries({ refetchType: "all" });

      userLogout();

      datadogRum.clearUser();
    }
  };

  const closeFrame = () => {
    setShow(false);

    setTimeout(() => {
      customPostMessage(
        {
          type: "closeIframe",
        } as CloseFrameMessage,
        "*",
        window.parent,
      );
    }, 300);
  };

  React.useEffect(() => {
    const handleMessage = (event: MessageEvent<HostIFrameMessage>) => {
      if (event.data.type === "position") {
        setShow(true);
      }
    };

    const remove = messageListener(handleMessage);

    return () => {
      remove();
    };
  }, [setShow]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        setUser: handleSetUser,
        closeFrame,
        show,
        loading,
        setLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
