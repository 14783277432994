const checkLocalStorage = () => {
  if (typeof window !== "undefined") {
    return true;
  }

  return false;
};

export const enum LocalStorageItems {
  "user_id" = "user_id",
  "access_token" = "access_token",
  "app_version" = "app_version",
  "contentConsumerId" = "contentConsumerId",
  "country" = "country",
  "email" = "email",
  "federated_access_token" = "federated_access_token",
  "name_id" = "name_id",
  "name_type" = "name_type",
  "name" = "name",
  "persona_id" = "persona_id",
  "phone_number" = "phone_number",
  "refresh_token" = "refresh_token",
  "OnboardOrigin" = "OnboardOrigin",
  "dimensions.height" = "dimensions.height",
  "dimensions.width" = "dimensions.width",
  "api_key" = "api_key",
  "org_code" = "org_code",
  "postal_code" = "postal_code"
}

export type LocalStorageItem = keyof typeof LocalStorageItems;

export function setItem(name: LocalStorageItem, value: string): void;
export function setItem(name: string, value: string): void;
export function setItem(name: LocalStorageItem | string, value: string): void {
  try {
    if (checkLocalStorage()) {
      localStorage.setItem(name, value);
    }
  } catch (e) {
    console.error(
      "Error happened during setting item to localStorage",
      e,
      name,
      value,
    );
  }
}

export function getItem(name: LocalStorageItem): string | null;
export function getItem(name: string): string | null;
export function getItem(name: LocalStorageItem | string): string | null {
  try {
    if (checkLocalStorage()) {
      return localStorage.getItem(name);
    }
  } catch (e) {
    console.error(
      "Error happened during getting item to localStorage",
      e,
      name,
    );
  }

  return null;
}

export const getAll = (): object | null => {
  try {
    if (checkLocalStorage()) {
      return { ...localStorage };
    }
  } catch (e) {
    console.error(
      "Error happened during getting item to localStorage",
      e,
      name,
    );
  }

  return null;
};

export const deleteItem = (name: LocalStorageItem): void => {
  try {
    if (checkLocalStorage()) {
      localStorage.removeItem(name);
    }
  } catch (e) {
    console.error(
      "Error happened during getting item to localStorage",
      e,
      name,
    );
  }
};
