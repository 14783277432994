import { usePathname, useRouter } from "next/navigation";
import { useAuth } from "./useAuth";
import { useEffect } from "react";

const useCustomRouter = () => {
  const router = useRouter();
  const pathname = usePathname();
  const { setLoading } = useAuth();

  const wrapper = (fn: Function) => (...rest: unknown[]) => {
    if (rest.at(0)) {
        //assume it's a new path
        if (!String(rest.at(0)).startsWith(window.location.pathname)) {
            setLoading(true);
        }
    }

    return fn(...rest);
  }

  useEffect(() => {
    if (pathname === window.location.pathname) {
      console.log("Same path: ", pathname);
    }

    setLoading(false);
  }, [setLoading, pathname]);

  return {
    push: wrapper(router.push),
    replace: wrapper(router.replace),
    back: wrapper(router.back),
    forward: wrapper(router.forward),
    refresh: router.refresh,
    prefetch: router.prefetch,
  };
};

export default useCustomRouter;
